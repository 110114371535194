<template>
    <div class="block-middle" id="link-account">
        <div class="subtitle-container">
            <img src="@/assets/img/subtitle_frame.png" class="subtitle-image" />
            <p>Please login to your account</p>
        </div>
        <p>To receive the Twitch Drops, you must be logged in to both your Twitch and Steam accounts.</p>
        <div class="connect-view">
            <ConnectView />
        </div>
    </div>
</template>

<script>
import ConnectView from '@/components/ConnectView.vue'

export default {
    name: 'MainMiddleView',
    components: {
        ConnectView,
    }
}
</script>

<style scoped>
.block-middle {
    height: 900px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.3);
    text-align: center;
    overflow-x: hidden;
}

.subtitle-container {
    position: relative;
    display: flex;
    justify-content: center;
    height: 235px;
}

.subtitle-image {
    width: 1008px;
}

.subtitle-container p {
    position: absolute;
    font-size: 32px;
    font-weight: 700;
    margin: auto;
    width: 90vw;
    top: 92px;
    right: 0;
    left: 0;
}

p {
    font-size: 24px;
    font-weight: 700;
    width: 90vw;
    max-width: 720px;
    margin: -48px auto;
    display: inline-block;
}

.connect-view {
    margin-top: 36px;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 959px) {
    .block-middle {
        height: 840px;
    }

    .subtitle-image {
        width: 700px;
    }

    .subtitle-container p {
        font-size: 28px;
        top: 96px;
    }

    p {
        font-size: 16px;
        max-width: 96vw;
    }
}
</style>