<template>
  <router-view/>
</template>

<style>
html {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    background-image: url("@/assets/img/bg.png");
    background-size: 1920px;
    height: 100%;
    margin: 0;
    font-family: "Noto Sans JP", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}
</style>
