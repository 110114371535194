<template>
  <div class="block-top">
    <img src="@/assets/img/main_background.png" class="main-image" />
    <div class="container">
      <img src="@/assets/img/palworld_logo.svg" class="palworld-logo" /><br />
      <div class="title">
        <img src="@/assets/img/title_frame.svg" class="title-frame" />
        <p>TWITCH DROPS</p>
      </div>
      <p class="sub-title">Watch Twitch Livestreams to Claim Rewards !</p>
      <p class="description">You can earn Twitch Drops by watching streams on Twitch during the campaign period.<br />
        To participate and earn Twitch Drops, you need to link your Twitch and Steam accounts.</p>
      <div class="link-button-container">
        <LargeButtonOn text="Link Now" link="#link-account" />
      </div>
      <div class="arrow-button">
        <a href="#link-account">
          <img src="@/assets/img/arrow.png" class="allow-image" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import LargeButtonOn from '@/components/LargeButtonOn.vue'

export default {
  name: 'MainTopView',
  components: {
    LargeButtonOn,
  }
}
</script>

<style scoped>
.block-top {
  width: 100%;
  height: calc(500px + 30vw);
  position: relative;
  overflow-x: hidden;
}

.block-top .main-image {
  width: 100%;
  position: absolute;
  top: 0;
}

.block-top .container {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  text-align: center;
}

.block-top .palworld-logo {
  width: 20vw;
  margin-top: 14vw;
}

.block-top .title {
  position: relative;
  margin-top: 1vw;
}

.block-top .title .title-frame {
  width: 38.1vw;
}

.block-top .title p {
  position: absolute;
  font-size: 4.4vw;
  color: #E5FBFF;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  line-height: 7.7vw;
  letter-spacing: 8px;
  text-shadow: 0 0 10px rgba(255, 199, 0, 0.7), 0 0 10px rgba(255, 153, 0, 0.7);
}

.block-top .sub-title {
  font-size: 28px;
  font-weight: 700;
  margin: 36px auto 0 auto;
  max-width: 90vw;
}

.block-top .description {
  font-size: 20px;
  max-width: 900px;
  width: 90vw;
  margin: 16px auto;
}

.block-top .link-button-container {
  width: 324px;
  margin: 52px auto;
}

.block-top .arrow-button {
  height: 88px;
  width: 64px;
  position: relative;
  margin: 16px auto 16px auto;
}

.block-top .arrow-button .allow-image {
  /* width: 3.8vw; */
  width: 64px;
  animation-name: JumpAnimation;
  animation-fill-mode: backwards;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-delay: 0.5s;
  animation-direction: normal;
}

@keyframes JumpAnimation {
  0% {
    opacity: 0;
    transform: translateY(-24px);
  }

  100% {
    opacity: 1;
    transform: translateY(32px);
  }
}

@media screen and (max-width: 959px) {
  .block-top {
    height: 1000px;
  }

  .block-top .main-image {
    width: 1080px;
    left: calc(-540px + 50vw);
  }

  .block-top .palworld-logo {
    width: 256px;
    margin-top: 192px;
  }

  .block-top .title .title-frame {
    width: 314px;
  }

  .block-top .title p {
    font-size: 36px;
    line-height: 60px;
    letter-spacing: 4px;
  }

  .block-top .description {
    font-size: 16px;
  }
}
</style>