<template>
  <div class="bg-gradation">
    <MainTopView/>
    <MainMiddleView/>
    <MainBottomView/>
  </div>
</template>

<script>
// @ is an alias to /src
import MainTopView from '@/components/MainTopView.vue'
import MainMiddleView from '@/components/MainMiddleView.vue'
import MainBottomView from '@/components/MainBottomView.vue'

export default {
  name: 'MainView',
  components: {
    MainTopView,
    MainMiddleView,
    MainBottomView,
  }
}
</script>

<style>
p {
  color: #E5FBFF;
  text-shadow: 0 0 10px rgba(0, 180, 255, 0.7), 0 0 10px rgba(0, 180, 255, 0.7);
}

.bg-gradation {
  background: rgb(107, 231, 255);
  background: linear-gradient(180deg, rgba(107, 231, 255, 0) 0%, rgba(107, 231, 255, 0.34) 100%);
  height: 100%;
}
</style>
