<template>
  <div class="button-box">
    <div class="button-target">
      <img src="@/assets/img/button_large_bg.png" class="button-bg" />
      <span class="button-bg-text">{{ text }}</span>
      <div></div>
      <a :href="link" class="button-link"></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LargeButtonOn',
  props: {
    text: String,
    link: String,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button-box {
  background-color: rgba(0, 0, 0, 1);
  width: 324px;
  height: 100px;
  min-width: 324px;
  z-index: 1;
}

.button-box .button-target {
  background-color: none;
  border: 2px solid rgba(230, 228, 223, 0.2);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-size: 40px;
  font-weight: 900;
  cursor: pointer;
  position: relative;
  display: inline-block;
  transition: width, height, color, text-shadow, border 0.3s;
  line-height: 100px;
  text-align: center;
  color: #FFFFFF !important;
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.6), 0 0 15px rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0 0 12px rgba(72, 208, 255, 0.5);
}

.button-box .button-target::before,
.button-box .button-target::after,
.button-box .button-target div::before,
.button-box .button-target div::after {
  width: 18px;
  height: 18px;
  background-color: #00000000;
  z-index: 2;
  content: "";
  position: absolute;
  transition: all 0.3s;
  border: 2px solid rgba(217, 217, 217, 0.5);
  box-sizing: border-box;
}

.button-box .button-target::before {
  top: -9px;
  left: -9px;
  border-left: 5px solid #009CFF;
  border-top: 5px solid #009CFF;
  border-right: none;
  border-bottom: none;
  box-shadow: 0 0 12px rgba(72, 208, 255, 0.5);
}

.button-box .button-target::after {
  bottom: -9px;
  right: -9px;
  border-left: none;
  border-top: none;
  border-right: 5px solid #009CFF;
  border-bottom: 5px solid #009CFF;
  box-shadow: 0 0 12px rgba(72, 208, 255, 0.5);
}

.button-box .button-target div::before {
  top: -9px;
  right: -9px;
  border-left: none;
  border-top: 5px solid #009CFF;
  border-right: 5px solid #009CFF;
  border-bottom: none;
  box-shadow: 0 0 12px rgba(72, 208, 255, 0.5);
}

.button-box .button-target div::after {
  bottom: -9px;
  left: -9px;
  border-left: 5px solid #009CFF;
  border-top: none;
  border-right: none;
  border-bottom: 5px solid #009CFF;
  box-shadow: 0 0 12px rgba(72, 208, 255, 0.5);
}

.button-bg {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 324px;
  height: 100px;
  transition: opacity 0.3s;
}

.button-bg-text {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
}

.button-link {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 5;
  transition: all 0.2s;
}

.button-box .button-link:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
