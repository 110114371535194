<template>
    <div class="container">
        <div class="card-frame">
            <div class="loading" v-if="isLoading">
                <div class="progress"></div>
            </div>
            <div v-if="!isLoading">
                <div class="flex-container">
                    <div class="service-container">
                        <p class="service-name">① Twitch Account</p>
                        <img src="@/assets/img/vector_line.png" class="img-vector" />
                        <img src="@/assets/img/logo_twitch.png" class="img-logo"
                            :class="{ 'margin-top-44': isTwitchLoggedIn && isSteamLoggedIn }" />
                        <div v-if="!isTwitchLoggedIn">
                            <SmallButton text="Log In"
                                link="https://api.twitch-drops.palworldgame.com/oauth/twitch/login"
                                class="button-login"
                                eventName="login-twitch" />
                        </div>
                        <div v-if="isTwitchLoggedIn">
                            <p class="username" :class="{ 'margin-top-36': isTwitchLoggedIn && isSteamLoggedIn }">{{
                twitchUserName }}</p>
                        </div>
                    </div>
                    <div class="link-image-container">
                        <img src="@/assets/img/unlink.png" v-if="!(isTwitchLoggedIn && isSteamLoggedIn)" />
                        <img src="@/assets/img/linked.png" v-if="isTwitchLoggedIn && isSteamLoggedIn"
                            class="margin-top-136" />
                    </div>
                    <div class="service-container">
                        <p class="service-name">② Steam Account</p>
                        <img src="@/assets/img/vector_line.png" class="img-vector" />
                        <img src="@/assets/img/logo_steam.png" class="img-logo"
                            :class="{ 'margin-top-44': isTwitchLoggedIn && isSteamLoggedIn }" />
                        <div v-if="canSteamLogIn && !isSteamLoggedIn">
                            <SmallButton text="Log In"
                                link="https://api.twitch-drops.palworldgame.com/oauth/steam/login"
                                class="button-login"
                                eventName="login-steam" />
                        </div>
                        <div v-if="!canSteamLogIn">
                            <p class="n-text">You must login to Twitch first.</p>
                        </div>
                        <div v-if="isSteamLoggedIn">
                            <p class="username" :class="{ 'margin-top-36': isTwitchLoggedIn && isSteamLoggedIn }">{{
                steamUserName }}</p>
                            <a class="change-account"
                                href="https://api.twitch-drops.palworldgame.com/oauth/steam/login">Change Account</a>
                        </div>
                    </div>
                </div>
                <p class="linked-text" v-if="isTwitchLoggedIn && isSteamLoggedIn">Your Accounts has been Linked !</p>
            </div>
        </div>
        <div class="image-frame">
            <img src="@/assets/img/cattiva.png" class="image-cattiva" />
            <img src="@/assets/img/depresso.png" class="image-depresso" />
        </div>
    </div>
</template>

<script>
import SmallButton from '@/components/SmallButton.vue'

export default {
    name: 'ConnectView',
    data() {
        return {
            isLoading: true,
            isTwitchLoggedIn: false,
            isSteamLoggedIn: false,
            canTwitchLogIn: false,
            canSteamLogIn: false,
            twitchUserName: "",
            steamUserName: "",
        }
    },
    components: {
        SmallButton,
    },
    mounted() {
        const status = this.$cookies.get('status');
        if (status != null) {
            this.isTwitchLoggedIn = status.isTwitchLoggedIn;
            this.isSteamLoggedIn = status.isSteamLoggedIn;
            this.canTwitchLogIn = status.canTwitchLogIn;
            this.canSteamLogIn = status.canSteamLogIn;
            this.twitchUserName = status.twitchUserName;
            this.steamUserName = status.steamUserName;
            this.isLoading = false;
        }else{
            console.log("Fetching status")
            fetch('https://api.twitch-drops.palworldgame.com/api/user/status', {
                mode: "cors",
                credentials: "include",
            }).then(response => {
                if (!response.ok) {
                    // error
                    this.isLoading = false;
                    return
                }
                return response.json();
            })
            .then(data => {
                if (data.status == "nologgedin") {
                    this.isTwitchLoggedIn = false;
                    this.isSteamLoggedIn = false;
                    this.canTwitchLogIn = true;
                    this.canSteamLogIn = false;
                } else if (data.status == "nolinked") {
                    this.isTwitchLoggedIn = true;
                    this.isSteamLoggedIn = false;
                    this.canTwitchLogIn = false;
                    this.canSteamLogIn = true;
                    this.twitchUserName = data.twitch.user_name;
                } else {
                    if (data.steam.nick_name == "") {
                        data.steam.nick_name = "logged in"
                    }
                    this.isTwitchLoggedIn = true;
                    this.isSteamLoggedIn = true;
                    this.canTwitchLogIn = false;
                    this.canSteamLogIn = true;
                    this.twitchUserName = data.twitch.user_name;
                    this.steamUserName = data.steam.nick_name;
                    // Cookieにキャッシュする
                    var status = {
                        isTwitchLoggedIn: true,
                        isSteamLoggedIn: true,
                        canTwitchLogIn: false,
                        canSteamLogIn: true,
                        twitchUserName: data.twitch.user_name,
                        steamUserName: data.steam.nick_name
                    }
                    this.$cookies.set('status', status, '1d', "/", "twitch-drops.palworldgame.com", true, "None"); 
                }
                this.isLoading = false;
            })
            .catch(error => {
                // error
                this.isLoading = false;
                return error
            });
        }
    }
}

</script>

<style scoped>
.container {
    position: relative;
    width: 800px;
}

.card-frame {
    width: 800px;
    height: 410px;
    border: 2px solid rgba(230, 228, 223, 0.2);
    background-color: rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
}

.flex-container {
    display: flex;
    justify-content: center;
    width: 625px;
    margin: 0 auto;
}

.service-container {
    width: 250px;
    height: 100%;
    position: relative;
}

.service-container .service-name {
    font-size: 28px;
    margin-top: 42px;
    margin-bottom: 0;
    text-wrap: nowrap;
}

.service-container .img-vector {
    position: absolute;
    width: 250px;
    left: 0;
    margin-top: -8px;
}

.service-container .img-logo {
    height: 60px;
    margin-top: 72px;
}

.service-container .button-login {
    margin-top: 52px;
}

.service-container .username {
    font-size: 28px;
    margin-top: 60px;
    margin-bottom: 0;
}

.service-container .n-text {
    font-size: 20px;
    width: 200px;
    margin: 48px auto 0 auto;
}

.service-container .change-account {
    color: #E5FBFF;
    text-shadow: 0 0 10px rgba(0, 180, 255, 0.7), 0 0 10px rgba(0, 180, 255, 0.7);
    margin-top: 16px;
    font-size: 16px;
    display: inline-block;
}

.service-container .margin-top-44 {
    margin-top: 44px !important;
}

.service-container .margin-top-36 {
    margin-top: 36px !important;
}

.linked-text {
    font-size: 36px;
    font-weight: 700;
    text-shadow: 0 0 10px rgba(255, 199, 0, 0.7), 0 0 10px rgba(255, 153, 0, 0.7) !important;
    margin-top: 20px;
}

.link-image-container {
    width: 100px;
    margin: 0 12.5px;
}

.link-image-container img {
    margin-top: 148px;
    width: 100%;
}

.link-image-container .margin-top-136 {
    margin-top: 136px !important;
}

.image-frame {
    position: absolute;
    top: 192px;
    width: 1000px;
}

.image-frame .image-cattiva {
    position: absolute;
    width: 180px;
    left: -120px;
}

.image-frame .image-depresso {
    position: absolute;
    width: 250px;
    right: 70px;
}

.loading {
    margin-top: 204px;
    margin-left: auto;
    margin-right: auto;
    width: 720px;
    height: 8px;
}

.loading .progress {
    background-color: #E5FBFF;
    box-shadow: 0 0 10px rgba(0, 180, 255, 0.7), 0 0 10px rgba(0, 180, 255, 0.7);
    height: 8px;
    animation: progressBar 1.5s linear infinite;
}

@keyframes progressBar {
    0% {
        width: 0px;
    }

    30% {
        width: 300px;
        transform: translateX(0px);
    }

    70% {
        width: 300px;
        transform: translateX(420px);
    }

    100% {
        transform: translateX(720px);
        width: 0px;
    }
}

@media screen and (max-width: 959px) {
    .container {
        width: 95vw;
    }

    .card-frame {
        width: 95vw;
        height: 280px;
    }

    .flex-container {
        width: 92vw;
        margin: 0 auto;
    }

    .service-container {
        width: 40vw;
    }

    .service-container .service-name {
        font-size: 16px;
        margin-top: 28px;
    }

    .service-container .img-vector {
        width: 40vw;
        margin-top: -4px;
    }

    .service-container .img-logo {
        height: 40px;
        margin-top: 32px;
    }

    .service-container .button-login {
        margin: 24px auto 0 auto;
        width: 115px;
    }

    .service-container .username {
        font-size: 18px;
        margin-top: 28px;
    }

    .service-container .n-text {
        font-size: 14px;
        width: 36vw;
        margin: 20px auto 0 auto;
    }

    .service-container .change-account {
        margin-top: 0px;
        font-size: 12px;
    }

    .service-container .margin-top-44 {
        margin-top: 32px !important;
    }

    .service-container .margin-top-36 {
        margin-top: 28px !important;
    }

    .linked-text {
        font-size: 20px;
        margin-top: 20px;
    }

    .link-image-container {
        width: 64px;
        margin: 0 0;
    }

    .link-image-container img {
        margin-top: 80px;
    }

    .link-image-container .margin-top-136 {
        margin-top: 88px !important;
    }

    .image-frame {
        position: absolute;
        top: 256px;
        width: 100vw;
    }

    .image-frame .image-cattiva {
        position: absolute;
        width: 140px;
        left: -12px;
    }

    .image-frame .image-depresso {
        position: absolute;
        width: 190px;
        right: 20px;
    }

    .loading {
        margin-top: 144px;
        width: 90vw;
        height: 8px;
    }
}
</style>