<template>
  <div class="button-box">
    <div class="button-target">
      <img src="@/assets/img/button_large_bg.png" class="button-bg" />
      <span class="button-bg-text">{{ text }}</span>
      <div></div>
      <a :href="link" class="button-link" @click="onClick"></a>
    </div>
  </div>
</template>

<script>
import { event } from 'vue-gtag'

export default {
  name: 'SmallButton',
  props: {
    text: String,
    link: String,
    eventName: String,
  },
  methods: {
    onClick: function() {
      event(this.eventName)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button-box {
  background-color: rgba(0, 0, 0, 1);
  width: 250px;
  height: 60px;
  min-width: 250px;
  z-index: 1;
}

.button-box .button-target {
  background-color: transparent;
  border: 2px solid rgba(230, 228, 223, 0.2);
  box-sizing: border-box;
  color: #E5FBFF;
  width: 100%;
  height: 100%;
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
  position: relative;
  display: inline-block;
  transition: width, height, color, text-shadow, border 0.3s;
  text-shadow: 0 0 10px #00B4FF, 0 0 10px #00B4FF;
  line-height: 52px;
  text-align: center;
}

.button-box .button-target::before,
.button-box .button-target::after,
.button-box .button-target div::before,
.button-box .button-target div::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 3px;
  z-index: 5;
  transition: all 0.3s;
  border: 2px solid rgba(217, 217, 217, 0.5);
  box-sizing: border-box;
}

.button-box .button-target::before {
  top: -2px;
  left: -2px;
  border-left: 5px solid rgba(217, 217, 217, 0.5);
  border-top: 5px solid rgba(217, 217, 217, 0.5);
  border-right: none;
  border-bottom: none;
}

.button-box .button-target::after {
  bottom: -2px;
  right: -2px;
  border-left: none;
  border-top: none;
  border-right: 5px solid rgba(217, 217, 217, 0.5);
  border-bottom: 5px solid rgba(217, 217, 217, 0.5);
}

.button-box .button-target div::before {
  top: -2px;
  right: -2px;
  border-left: none;
  border-top: 5px solid rgba(217, 217, 217, 0.5);
  border-right: 5px solid rgba(217, 217, 217, 0.5);
  border-bottom: none;
}

.button-box .button-target div::after {
  bottom: -2px;
  left: -2px;
  border-left: 5px solid rgba(217, 217, 217, 0.5);
  border-top: none;
  border-right: none;
  border-bottom: 5px solid rgba(217, 217, 217, 0.5);
}

.button-box .button-target:hover {
  background: none;
  /* border: 2px solid #34F1FD; */
  color: #FFFFFF !important;
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.6), 0 0 15px rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0 0 12px #48D0FF;
}

.button-box .button-target:hover::before,
.button-box .button-target:hover::after,
.button-box .button-target:hover div::before,
.button-box .button-target:hover div::after {
  width: 18px;
  height: 18px;
  background-color: #00000000;
  z-index: 2;
}

.button-box .button-target:hover::before {
  top: -9px;
  left: -9px;
  border-color: #009CFF;
  box-shadow: 0 0 12px rgba(72, 208, 255, 0.5);
}

.button-box .button-target:hover::after {
  bottom: -9px;
  right: -9px;
  border-color: #009CFF;
  box-shadow: 0 0 12px rgba(72, 208, 255, 0.5);
}

.button-box .button-target:hover div::before {
  top: -9px;
  right: -9px;
  border-color: #009CFF;
  box-shadow: 0 0 12px rgba(72, 208, 255, 0.5);
}

.button-box .button-target:hover div::after {
  bottom: -9px;
  left: -9px;
  border-color: #009CFF;
  box-shadow: 0 0 12px rgba(72, 208, 255, 0.5);
}

.button-bg {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 250px;
  height: 60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.button-bg-text {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
}

.button-link {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 5;
}

.button-box .button-target:hover .button-bg {
  opacity: 1;
}

@media screen and (max-width: 959px) {
  .button-box {
    width: 115px;
    height: 28px;
    min-width: 115px;
  }

  .button-box .button-target {
    font-size: 18px;
    line-height: 22px;
  }

  .button-bg {
    top: -2px;
    left: -2px;
    width: 115px;
    height: 28px;
  }
}
</style>
