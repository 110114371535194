<template>
    <div class="block-bottom" id="link-account">
        <div class="subtitle-container">
            <img src="@/assets/img/subtitle_frame.png" class="subtitle-image" />
            <p>Frequently Asked Questions</p>
        </div>
        <div class="faq-container">
            <div class="faq-box" v-for="(d, i) in this.faqList" :key="i">
                <div class="faq-question">
                    <p>{{ i + 1 }}. {{ d.question }}</p>
                </div>
                <div class="faq-answer">
                    <div v-html="d.answer"></div>
                </div>
            </div>
        </div>
        <div class="image-container">
            <div class="image-jetragon">
                <img src="@/assets/img/jetragon.png" />
            </div>
            <div class="image-quivern">
                <img src="@/assets/img/quivern.png" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBottomView',
    data() {
        return {
            faqList: [],
        }
    },
    mounted() {
        this.faqList.push({
            question: "How do I earn Twitch Drops?",
            answer: "During the campaign period, watch streams with the \"Drops Enabled\" tag for a certain amount of time. Some campaigns may specify designated streamers to watch.<br/>Additionally, you need to link your account on this page before earning rewards on Twitch.",
        })
        this.faqList.push({
            question: "Is there a way to check the progress of Drops?",
            answer: "You can track your progress on the <a href='https://www.twitch.tv/drops/inventory' target='_blank' style='color: #E5FBFF;'>Twitch Drops inventory page</a>.",
        })
        this.faqList.push({
            question: "Is Twitch Prime required to participate in Twitch Drops?",
            answer: "No, you only need a Twitch account and a Steam account that owns Palworld.",
        })
        this.faqList.push({
            question: "Can Twitch Drops be earned on any platform?",
            answer: "No, rewards are only available for the Steam version. They cannot be received on other platform versions.",
        })
        this.faqList.push({
            question: "I want to link a different Steam account to a Twitch account that is already linked.",
            answer: "After logging in with your Twitch account, you can select \"Change Account\" to link to a different Steam account.<br/>Please note that campaign rewards that have already been claimed cannot be earned again.",
        })
        this.faqList.push({
            question: "I want to link a different Twitch account to a Steam account that is already linked.",
            answer: "To do this, log in with the Twitch account you want to link. You will need to log out of the previous Twitch account from the browser to switch accounts.<br/>Please note that campaign rewards that have already been claimed cannot be earned again.",
        })
        this.faqList.push({
            question: "Can you earn Twitch Drops faster by watching multiple streams?",
            answer: "No, progress is only calculated on one active stream, even if you watch multiple streams simultaneously.",
        })
    }
}
</script>

<style scoped>
.block-bottom {
    position: relative;
    background-color: rgba(0, 0, 0, 0.4);
    text-align: center;
    overflow-x: hidden;
}

.subtitle-container {
    position: relative;
    display: flex;
    justify-content: center;
    height: 235px;
}

.subtitle-image {
    width: 1008px;
}

.subtitle-container p {
    position: absolute;
    font-size: 32px;
    font-weight: 700;
    margin: auto;
    width: 90vw;
    top: 92px;
    right: 0;
    left: 0;
}

.faq-container {
    width: 840px;
    margin: 0px auto 24px auto;
    text-align: left;
}

.faq-container .faq-box {
    margin-top: 0;
    margin-bottom: 28px;
}

.faq-container .faq-box .faq-question p {
    margin: 0;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 700;
}

.faq-container .faq-box .faq-answer div {
    margin: 0;
    font-size: 20px;
    margin-left: 28px;
    color: #E5FBFF;
    text-shadow: 0 0 10px rgba(0, 180, 255, 0.7), 0 0 10px rgba(0, 180, 255, 0.7);
}

.image-container {
    position: relative;
    margin: 48px auto 24px auto;
    max-width: 1440px;
    height: 30vw;
    max-height: 400px;
}

.image-container .image-jetragon {
    position: absolute;
    left: -100px;
}

.image-container .image-jetragon img {
    max-width: 520px;
    width: 40vw;
}

.image-container .image-quivern {
    position: absolute;
    bottom: 0;
    right: -128px;
    max-width: 640px;
    width: 50vw;
}

.image-container .image-quivern img {
    width: 100%;
}

@media screen and (max-width: 959px) {
    .subtitle-image {
        width: 700px;
    }

    .subtitle-container p {
        font-size: 28px;
        top: 96px;
    }

    .faq-container {
        width: 90vw;
        margin: -44px auto 24px auto;
    }

    .faq-container .faq-box {
        margin-top: 0;
        margin-bottom: 18px;
    }

    .faq-container .faq-box .faq-question p {
        margin-bottom: 8px;
        font-size: 14px;
    }

    .faq-container .faq-box .faq-answer div {
        font-size: 12px;
        margin-left: 20px;
    }

    .image-container {
        margin: 12px auto 24px auto;
        height: 200px;
    }

    .image-container .image-jetragon {
        left: -75px;
    }

    .image-container .image-jetragon img {
        width: 250px;
    }

    .image-container .image-quivern {
        right: -96px;
        width: 275px;
    }
}
</style>