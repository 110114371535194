import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGTag from 'vue-gtag'
import VueCookies from 'vue-cookies';
const app = createApp(App)

app.use(
    VueGTag,
    {
      config: {
        id: '{ G-WBBP6G8Y5E }'
      }
    },
    router
  )
  

app.use(router)
app.use(VueCookies)
app.mount('#app')
